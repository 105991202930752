import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import css from './form.module.css';
import cn from 'classnames';

import Button from '../Button';

function Form() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [consent, setConsent] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  useEffect(() => {
    if (window) {
      setIsSuccessful(window.location.href.indexOf('?success=true') !== -1);
    }
  }, []);

  return (
    <form
      name="Contact"
      method="POST"
      data-netlify="true"
      action="/contact?success=true"
    >
      <input type="hidden" name="form-name" value="Contact" />

      <div
        className={cn(css.alert, {
          [css.alertHidden]: !isSuccessful
        })}
      >
        Your message was successfully sent. I will respond as soon as possible.
        Thank you.
      </div>

      <div className={css.formGroup}>
        <label htmlFor="firstName" className={css.label}>
          Enter First Name
        </label>

        <input
          type="text"
          id="firstName"
          name="First Name"
          placeholder="First Name"
          value={firstName}
          className={css.textInput}
          onChange={event => setFirstName(event.target.value)}
          required
        />
      </div>

      <div className={css.formGroup}>
        <label htmlFor="lastName" className={css.label}>
          Enter Last Name
        </label>

        <input
          type="text"
          id="lastName"
          name="Last Name"
          placeholder="Last Name"
          value={lastName}
          className={css.textInput}
          onChange={event => setLastName(event.target.value)}
          required
        />
      </div>

      <div className={css.formGroup}>
        <label htmlFor="email" className={css.label}>
          Enter Email
        </label>

        <input
          type="email"
          id="email"
          name="Email"
          placeholder="Email"
          value={email}
          className={css.textInput}
          onChange={event => setEmail(event.target.value)}
          required
        />
      </div>

      <div className={css.formGroup}>
        <label htmlFor="subject" className={css.label}>
          Enter Subject
        </label>

        <input
          type="text"
          id="subject"
          name="Subject"
          placeholder="Subject"
          value={subject}
          className={css.textInput}
          onChange={event => setSubject(event.target.value)}
          required
        />
      </div>

      <div className={css.formGroup}>
        <label htmlFor="message" className={css.label}>
          Enter Message
        </label>

        <textarea
          id="message"
          name="Message"
          placeholder="Message"
          value={message}
          className={css.textInput}
          onChange={event => setMessage(event.target.value)}
          required
        />
      </div>

      <label className={css.checkboxLabel}>
        <input
          type="checkbox"
          checked={consent}
          onChange={() => setConsent(!consent)}
          name="Consent"
          className={css.checkboxInput}
          required
        />
        <em>
          I agree to the <Link to={'/privacy-policy/'}>privacy policy</Link>
        </em>
      </label>

      <div className={css.buttonWrapper}>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
}

export default Form;
